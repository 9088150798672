import React, { useState, useRef, useEffect, useContext } from 'react';
import GoogleMapReact from 'google-map-react';
import googleMapStyles from '../../constants/GoogleMapStyles';
import { withRouter, useHistory } from 'react-router-dom';
import DispatchContext from '../../context/DispatchContext';
import StateContext from '../../context/StateContext';
import useSupercluster from 'use-supercluster';

import { MapWrapper, MarkerImg, ClusterMarkerStyled } from './styles/map';
import markerNew from '../../assets/svg/marker-new.svg';
import markerOff from '../../assets/svg/marker-off-icon.svg'
import markerOffNeutre from '../../assets/svg/marker-off-neutre.svg'
import markerOn from '../../assets/svg/marker-on-icon.svg'
import markerOnNeutre from '../../assets/svg/marker-on-neutre.svg'


const queryString = require('query-string');
const Marker = ({ children }) => children;

let Map = ({ location, children }) => {
  const mapRef = useRef();

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const mapOptions = {
    styles: googleMapStyles.mapStyle,
    minZoom: 5,
    maxZoom: 16,
  };

  const defaultProps = {
    center: {
      lat: 48.8889462,
      lng: 2.349854800000003,
    },
    zoom: 8,
  };

  const getContainerHeight = () => {
    let newHeight;
    if (['iPhone Simulator', 'iPhone'].includes(navigator.platform))
      newHeight = 'calc(100vh - 180px)';
    else
      newHeight =
        window.innerWidth > 1200 ? 'calc(100vh - 128px)' : 'calc(100vh - 74px)';
    return newHeight;
  };

  const initialContainer = { width: '100%', height: getContainerHeight() };

  let [zoom, setZoom] = useState(defaultProps.zoom);
  let [center, setCenter] = useState(defaultProps.center);
  let [containerSize, setContainerSize] = useState(initialContainer);
  let [bounds, setBounds] = useState([])

  const points = appState.markers.map((info) => ({
    type: 'Feature',
    properties: { cluster: false,  key: info['_id'], isMine: info.isMine, allData: info},
    geometry: {
      type: 'Point',
      coordinates: [
        info.locationGeoJSON.coordinates[0],
        info.locationGeoJSON.coordinates[1],
      ],
    },
  }));


  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 8 },
  });

  useEffect(() => {
    const updateContainer = () => {
      setContainerSize({ width: '100%', height: getContainerHeight() });
    };

    window.addEventListener('resize', updateContainer);

    return () => window.removeEventListener('resize', updateContainer);
  }, []);

  useEffect(() => {
    if (appState.center.lat && appState.center.lng) setCenter(appState.center);
  }, [appState.center]);

  useEffect(() => {
    setZoom((z) => (z < 11 ? 11 : z));
    if (mapRef.current)
      setTimeout(() => {
        offsetMap();
      });
  }, [appState.panMap]);

  useEffect(() => {
    if (appState.searchBounds && mapRef.current) {
      mapRef.current.fitBounds(appState.searchBounds);
      setTimeout(() => {
        mapRef.current.setZoom(Math.min(11, mapRef.current.getZoom()));
        offsetMap();
      });
    }
  }, [appState.searchBounds]);

  const offsetMap = () => {
    let listWidth, listHeight;
    if (window.innerWidth > 1200) {
      // list width + spacing from the right
      listWidth = (window.innerWidth * 40) / 100 + 3.5 * 16;
      mapRef.current.panBy(listWidth / 2, 0);
    } else {
      listHeight = (window.innerHeight * 60) / 100;
      mapRef.current.panBy(0, listHeight / 2);
    }
  };

  const getGeojsonBounds = (bounds) => {
    let mainArray = [];
    let boundsArray = [];
    let { se, ne, sw, nw } = bounds;
    // ORDER IS IMPORTANT!!
    // points need to be adjacent
    // array starting and ending with same point
    boundsArray.push(ne, nw, sw, se, ne);
    for (let bound of boundsArray) {
      if (window.google && window.google.maps) {
        // transform bound x/y to lat/lng
        let boundLatLng = new window.google.maps.LatLng(bound.lat, bound.lng);
        bound.lat = boundLatLng.lat();
        bound.lng = boundLatLng.lng();
      }
      // geoJSON format = lng / lat
      let reverseBounds = [bound.lng, bound.lat];
      mainArray.push(reverseBounds);
    }
    return [mainArray];
  };

  const onMapChange = ({ zoom, center, bounds }) => {
    setZoom(zoom);
    // geoJsonBounds = GeoJSON format polygon
    const cloneBounds = { ...bounds, geoJsonBounds: getGeojsonBounds(bounds) };
    appDispatch({ type: 'setBounds', value: cloneBounds });
    appDispatch({ type: 'center', value: center });
    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat
    ]);
  };

  const onMapClick = ({ lat, lng }) => {
    if (
      appState.infoOpen &&
      appState.loggedIn &&
      appState.newInfoStatus === null
    )
      appDispatch({ type: 'addMarker', value: { lat, lng, source: 'map' } });
  };

  const handleApiLoaded = (map) => {
    mapRef.current = map;
    offsetMap();

    let zoomLevel, coord;
    const parsed = queryString.parse(location.search);

    if (parsed.zoom) zoomLevel = parseInt(parsed.zoom.replace(/\D/g, ''));
    if (parsed.centroid) coord = checkCoordinates(parsed.centroid);

    if (coord) appDispatch({ type: 'centerUrl', value: coord });

    if (!isNaN(zoomLevel)) setZoom(zoomLevel);
  };

  const checkCoordinates = (coordinates) => {
    let centerTo = { lat: null, lng: null };
    try {
      centerTo.lat = JSON.parse(coordinates)[1]
        ? parseFloat(JSON.parse(coordinates)[1])
        : null;
      centerTo.lng = JSON.parse(coordinates)[0]
        ? parseFloat(JSON.parse(coordinates)[0])
        : null;
    } catch (e) {
      return false;
    }
    if (
      !isNaN(centerTo.lat) &&
      centerTo.lat >= -90 &&
      centerTo.lat <= 90 &&
      !isNaN(centerTo.lng) &&
      centerTo.lng >= -180 &&
      centerTo.lat <= 180
    )
      return centerTo;
    return false;
  };

    return (
        // Important! Always set the container height explicitly
        <MapWrapper
            style={containerSize}
            cursorAdd={appState.infoOpen && appState.loggedIn && appState.newInfoStatus === null}
            cursorNew={appState.newMarker.lng !== null && appState.newMarker.lat !== null && appState.newInfoStatus === null}>
            <GoogleMapReact
                options={mapOptions}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
                onChange={onMapChange}
                onClick={onMapClick}
            >
              {appState.newMarker.lng !== null && appState.newMarker.lat !== null && appState.infoOpen &&
                  <Map.Marker
                    markerOff={markerNew}
                    markerOn={markerNew}
                    lat={appState.newMarker.lat}
                    lng={appState.newMarker.lng}
                    newMarker={true}
                  />
                }

              {clusters.map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                  cluster: isCluster,
                  point_count: pointCount
                } = cluster.properties;


                if (isCluster) {
                  return (
                    <Marker
                      key={`cluster-${cluster.id}`}
                      lat={latitude}
                      lng={longitude}
                    >
                      <Map.ClusterMarker
                        supercluster={supercluster}
                        pointCount={pointCount}
                        cluster={cluster}
                        latitude={latitude}
                        longitude={longitude}
                        points={points}
                        mapRef={mapRef}
                        key={`clustermarker-${cluster.id}`}
                      >

                      </Map.ClusterMarker>
                    </Marker>
                  );
                }

                return (
                <Map.Marker
                  markerOff={cluster.properties.isMine ? markerOff : markerOffNeutre}
                  markerOn={cluster.properties.isMine ? markerOn : markerOnNeutre}
                  key={cluster.properties.key}
                  lat={cluster.geometry.coordinates[1]}
                  lng={cluster.geometry.coordinates[0]}
                  allData={cluster.properties}
                  />
                )
              })}
              {children}
            </GoogleMapReact>
        </MapWrapper>
    )
}

export default Map = withRouter(Map)


Map.Container = function MapContainer({ children }) {
    return (
        <main className="position-relative overflow-hidden">{children}</main>
    )
};

Map.Marker = function MapMarker({ allData, markerOff, markerOn, newMarker, ...restProps }) {

    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext)
    let history = useHistory();
    const [isActive, setActive] = useState(false)

    let activeElement, currentElement

  if (!newMarker) {
    if (appState.hoverList) activeElement = appState.hoverList['_id'];
    if (appState.activeMap) activeElement = appState.activeMap['_id'];
    if (appState.activeList) activeElement = appState.activeList['_id'];
    currentElement = allData?.allData?.['_id'];
  }

  const markerClicked = () => {
    if (!newMarker) {
      appDispatch({ type: 'markerClicked', value: allData.allData });
      history.push(`/${allData?.allData?.['slug']}-${allData?.allData?.['_id']}`);
    }
  };

  let imgActive = isActive || activeElement === currentElement;

  return (
    <MarkerImg
      src={imgActive ? markerOn : markerOff}
      imgactive={imgActive.toString()}
      infoopen={appState.infoOpen.toString()}
      {...restProps}
      onClick={markerClicked}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{ transform: 'translate(-50%, -100%)' }}
    ></MarkerImg>
  );
};

Map.ClusterMarker = function MapClusterMarker({ supercluster, pointCount, cluster, latitude, longitude, points, mapRef,  ...restProps }) {

  const markerClicked = () => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    );
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat: latitude, lng: longitude });
  };


return (
  <ClusterMarkerStyled
    onClick={markerClicked}
    pointCount= {pointCount}
  >
    {pointCount}
  </ClusterMarkerStyled>
);
};
